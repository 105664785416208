<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Edycja danych personalnych
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="flex justify-end mb-1 w-full px-1">
          <label class="inline-block mr-4">
            <input v-model="form.foreigner" true-value="1" false-value="0" class="w-auto" type="checkbox" :disabled="form.fake_person === '1'"> obcokrajowiec
          </label>
          <label class="inline-block">
            <input v-model="form.fake_person" true-value="1" false-value="0" class="w-auto" type="checkbox"> kartoteka witrualna
          </label>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>imię</label>
          <Autocomplete
            resource="forenames"
            :inputClass="errors.first_name !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.fake_person === '1'"
            v-model="form.first_name"
          />
          <p v-if="errors.first_name !== undefined" class="text-red-500 text-sm">{{ errors.first_name }}</p>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>drugie imię</label>
          <Autocomplete
            resource="forenames"
            :inputClass="errors.middle_name !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.fake_person === '1'"
            v-model="form.middle_name"
          />
          <p v-if="errors.middle_name !== undefined" class="text-red-500 text-sm">{{ errors.middle_name }}</p>
        </div>
        <div class="mb-4 w-full px-1">
          <label v-if="form.fake_person === '1'">nazwa</label>
          <label v-else>nazwisko</label>
          <input :class="{ 'border-red-300': errors.surname !== undefined }" v-model="form.surname" type="text">
          <p v-if="errors.surname !== undefined" class="text-red-500 text-sm">{{ errors.surname }}</p>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label v-if="form.foreigner !== '1'">pesel</label>
          <label v-else>nr paszportu</label>
          <input :class="{ 'border-red-300': errors.pesel_passport !== undefined }" v-model="form.pesel_passport" type="text" :disabled="form.fake_person === '1'">
          <p v-if="errors.pesel_passport !== undefined" class="text-red-500 text-sm">{{ errors.pesel_passport }}</p>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>data ur.</label>
          <input :class="{ 'border-red-300': errors.birth_date !== undefined }" v-model="form.birth_date" type="date" :disabled="form.fake_person === '1'">
          <p v-if="errors.birth_date !== undefined" class="text-red-500 text-sm">{{ errors.birth_date }}</p>
        </div>
        <div class="mb-4 w-11/12 md:w-5/12 px-1">
          <label>telefon</label>
          <input :class="{ 'border-red-300': errors.phone !== undefined }" v-model="form.phone" type="text">
          <p v-if="errors.phone !== undefined" class="text-red-500 text-sm">{{ errors.phone }}</p>
        </div>
        <div class="mb-4 w-1/12 px-1 text-center">
          <label>PL</label>
          <input v-model="form.regional_phone" true-value="1" false-value="0" type="checkbox">
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>email kontakt.</label>
          <input :class="{ 'border-red-300': errors.email !== undefined }" v-model="form.email" type="email">
          <p v-if="errors.email !== undefined" class="text-red-500 text-sm">{{ errors.email }}</p>
        </div>
        <hr class="w-full">
        <div class="mb-1 w-full px-1">
          <label>
            <input v-model="form.address.local_address" true-value="1" false-value="0" class="w-auto" type="checkbox" :disabled="form.fake_person === '1'"> Polska
          </label>
        </div>
        <div class="mb-4 w-3/5 md:w-1/2 px-1">
          <label>ulica</label>
          <input :class="{ 'border-red-300': errors['address.street'] !== undefined }" v-model="form.address.street" type="text" :disabled="form.fake_person === '1'">
          <p v-if="errors['address.street'] !== undefined" class="text-red-500 text-sm">{{ errors['address.street'] }}</p>
        </div>
        <div class="mb-4 w-2/5 md:w-1/2 px-1">
          <label>nr domu/mieszk.</label>
          <input :class="{ 'border-red-300': errors['address.house_apt_no'] !== undefined }" v-model="form.address.house_apt_no" type="text" :disabled="form.fake_person === '1'">
          <p v-if="errors['address.house_apt_no'] !== undefined" class="text-red-500 text-sm">{{ errors['address.house_apt_no'] }}</p>
        </div>
        <div class="mb-4 w-1/4 md:w-1/2 px-1">
          <label>kod poczt.</label>
          <Autocomplete
            resource="postal-codes"
            :inputClass="errors['address.postal_code'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.fake_person === '1'"
            v-model="form.address.postal_code"
            @selected="setCity"
          />
          <p v-if="errors['address.postal_code'] !== undefined" class="text-red-500 text-sm">{{ errors['address.postal_code'] }}</p>
        </div>
        <div class="mb-4 w-3/4 md:w-1/2 px-1">
          <label>miasto</label>
          <Autocomplete
            resource="cities"
            :inputClass="errors['address.city'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.fake_person === '1'"
            v-model="form.address.city"
          />
          <p v-if="errors['address.city'] !== undefined" class="text-red-500 text-sm">{{ errors['address.city'] }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>poczta</label>
          <Autocomplete
            resource="cities"
            :inputClass="errors['address.post_city'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.fake_person === '1'"
            v-model="form.address.post_city"
          />
          <p v-if="errors['address.post_city'] !== undefined" class="text-red-500 text-sm">{{ errors['address.post_city'] }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>region</label>
          <select v-model="form.address.region_id" :disabled="form.fake_person === '1'">
            <option value=""></option>
            <option v-for="region in regions" :key="region.id" :value="region.id">{{ region.name }}</option>
          </select>
          <p v-if="errors['address.region_id'] !== undefined" class="text-red-500 text-sm">{{ errors['address.region_id'] }}</p>
        </div>
        <div v-show="form.local_address === '0'" class="mb-4 w-full px-1">
          <label>państwo</label>
          <input :class="{ 'border-red-300': errors['address.country'] !== undefined }" v-model="form.address.country" type="text" :disabled="form.fake_person === '1'">
          <p v-if="errors['address.country'] !== undefined" class="text-red-500 text-sm">{{ errors['address.country'] }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>bank</label>
          <input :class="{ 'border-red-300': errors.bank !== undefined }" v-model="form.bank" type="text" :disabled="form.fake_person === '1'">
          <p v-if="errors.bank !== undefined" class="text-red-500 text-sm">{{ errors.bank }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>nr konta</label>
          <input :class="{ 'border-red-300': errors.bank_acct_no !== undefined }" v-model="form.bank_acct_no" type="text" :disabled="form.fake_person === '1'">
          <p v-if="errors.bank_acct_no !== undefined" class="text-red-500 text-sm">{{ errors.bank_acct_no }}</p>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import PersonService from '@/services/PersonService';
import { errorHandler } from '../mixins/errorHandler';
import Autocomplete from './Autocomplete.vue';

export default {
  name: 'PersonEdit',
  mixins: [errorHandler],
  components: { Autocomplete },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        fake_person: '',
        first_name: '',
        middle_name: '',
        surname: '',
        pesel_passport: '',
        foreigner: '',
        birth_date: '',
        phone: '',
        regional_phone: '',
        email: '',
        address: {
          local_address: '1',
          street: '',
          house_apt_no: '',
          postal_code: '',
          city: '',
          post_city: '',
          region_id: '',
          country: '',
        },
        bank: '',
        bank_acct_no: '',
      },
      errors: {},
      show: false,
    };
  },
  computed: {
    regions() {
      return this.$store.getters['regions/regions'];
    },
    fakePerson() {
      return this.form.fake_person;
    },
  },
  mounted() {
    if (this.regions.length === 0) {
      this.$store.dispatch('regions/get');
    }
    this.setForm();
    this.show = true;
  },
  methods: {
    save() {
      this.errors = {};
      PersonService.updatePerson(this.person.id, this.form)
        .then(() => {
          this.$emit('changed');
          this.show = false;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
        })
        .catch(error => {
          this.errors = this.resolveError(error);
        });
    },
    setCity(payload) {
      this.form.address.city = payload.city;
    },
    setForm() {
      Object.keys(this.form).forEach(field => {
        if (field === 'address') {
          if (this.person.address1_id) {
            Object.keys(this.form.address).forEach(attr => {
              this.form.address[attr] = this.person.address1[attr] !== null && this.person.address1[attr] !== undefined ? this.person.address1[attr].toString() : '';
            });
          } else {
            this.form.address.local_address = '1';
          }
        } else {
          this.form[field] = this.person[field] !== null ? this.person[field].toString() : '';
        }
      });
    },
    makePersonFake() {
      this.form.first_name = '';
      this.form.middle_name = '';
      this.form.pesel_passport = '';
      this.form.foreigner = '0';
      this.form.birth_date = '';
      this.form.address.street = '';
      this.form.address.house_apt_no = '';
      this.form.address.postal_code = '';
      this.form.address.city = '';
      this.form.address.post_city = '';
      this.form.address.region_id = '';
      this.form.address.country = '';
      this.form.bank = '';
      this.form.bank_acct_no = '';
    },
  },
  watch: {
    fakePerson(val, oldVal) {
      if (oldVal === '0' && val === '1') {
        this.makePersonFake();
      }
    },
  },
};
</script>
